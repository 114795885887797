<!--
 * @Author: wyq
 * @Date: 2021-06-08 14:35:14
 * @LastEditTime: 2021-09-15 15:07:22
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\login.vue
-->
<template>
  <div class="page-login">
    <div class="login-container">
      <img src="../assets/images/login/background.jpg" />
      <div class="main">
        <div class="logo">
          <img src="../assets/images/logo.png" />
        </div>
        <p>试题录入系统</p>
        <el-input v-model="form.username" placeholder="请输入内容">
          <i slot="prefix" class="el-input__icon iconfont icon_lockbeifen"></i>
        </el-input>
        <el-input placeholder="请输入密码" v-model="form.password" show-password>
          <i slot="prefix" class="el-input__icon iconfont icon_lock"></i>
        </el-input>
        <el-button type="success" @click="login" :loading="loading">登录</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      loading: false,
    }
  },
  methods: {
    register() {
      let params = this.form
      params.name = '吴雨晴'
      this.$service.register(params).then(() => {
        console.log('注册成功！')
      })
    },
    login() {
      let params = this.form
      this.loading = true
      this.$service
        .login(params)
        .then((res) => {
          this.login = false
          localStorage.setItem('token', res.accessToken)
          localStorage.setItem('userName', res.name)
          localStorage.setItem('userType', res.type) //账户类型 1管理员 2普通用户
          this.$router.push({
            name: 'QuestionBank',
            query: {
              grade: 2,
              subject: 2,
            },
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.page-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .login-container {
    display: flex;
    width: 1200px;
    height: 680px;
    background: #ffffff;
    box-shadow: 2px 2px 68px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    .main {
      flex: 1;
      padding-left: 51px;
      padding-right: 57px;
      .logo {
        padding: 32px 0;
        text-align: center;
        border-bottom: 1px solid #f0f0f0;
        box-shadow: 0px 1px 0px 0px #f0f0f0;
        img {
          width: 200px;
          height: 38px;
        }
      }
      p {
        font-size: 24px;
        font-weight: 800;
        color: #333333;
        line-height: 33px;
        margin-bottom: 82px;
      }
      .el-input + .el-input {
        margin-top: 24px;
      }
      .el-button {
        margin-top: 90px;
        width: 100%;
      }
    }
  }
}
</style>
